import classes from './Contact.module.css';
import girl from './asset/girl.webp';
import mail2 from './asset/mail2.png';
import map from './asset/map2.png';
import phone from './asset/smartphone2.png';


const Contact = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.left}>

                <img src={girl} className={classes.leftIn}></img>

            </div>
            <div className={classes.right}>
                <p className={classes.righthead}>Have any questions?</p>
                <p className={classes.righthead2}>Don’t hesitate to reach us.</p>
                <hr color='red' width='71rem'></hr>
                <div className={classes.rIn}>
                    <img src={mail2} alt='' className={classes.icon}></img>
                    <p>sriapticonsultancy@gmail.com</p>
                </div>

                <div className={classes.rIn}>
                    <img src={phone} alt='' className={classes.icon}></img>
                    <p>9960786665, 9158000255</p>
                </div>

                <div className={classes.rIn}>
                    <img src={map} alt='' className={classes.icon}></img>
                    <p>107, 'A' wing, 1st floor, Sorabh Hall, Tadiwala Road, pune-411001</p>
                </div>
            </div>
        </div>
    )
}

export default Contact;