import { useEffect } from 'react';
import classes from './HomeLoan.module.css';
import home from './asset/house.png';

import door from './asset/doorstep.png';
import clock from './asset/wall-clock.png';
import leader from './asset/leader.png';
import visible from './asset/visibility.png';
import transparent from './asset/opacity.png';
import exprt from './asset/leadership.png';
import network from './asset/networking.png';

const HomeLoan = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={classes.hold}>
            <div className={classes.titlehold}>
                <p className={classes.title}>Home Loan</p>
            </div>

            <div className={classes.divsHold}>
                <div className={classes.left}>
                    <p className={classes.leftIn}>Home Loan with Real</p>
                    <p className={classes.leftIn}>Value FinLoan is</p>
                    <p className={classes.leftIn}>available for</p>
                    <div className={classes.homehold}><img src={home} className={classes.home}></img></div>
                </div>
                <div className={classes.middle}>
                    <div className={classes.card}>
                        <p className={classes.cardTitle}>Purchase Of Flat Or House</p>
                        <p className={classes.cardtxt}>This is the most common type of home loan. Real value provides loans to those clients who want to buy a new residential property or old residential property.</p>
                    </div>
                    <div className={classes.card}>
                        <p className={classes.cardTitle}>Home Expansion/Extension Loans</p>
                        <p className={classes.cardtxt}>These are the loans taken by that individual who isinterestedin theexpansion of their particular property. Expansion can include construction of the new room, a floor, balcony, etc. Real Value will sure help you out here.</p>
                    </div>
                </div>
                <div className={classes.right}>
                    <div className={classes.card1}>
                        <p className={classes.cardTitle1}>Home Improvement</p>
                        <p className={classes.cardtxt1}>These loans are taken that individual who wishes to renovate their existing home.</p>
                    </div>
                    <div className={classes.card1}>
                        <p className={classes.cardTitle1}>Home Loan Transfer</p>
                        <p className={classes.cardtxt1}>A loan is taken from one bank by the client and wishes to transfer that whole loan to another bank. Generally, costumers who are not satisfied with the ongoing bank services opt for this kind of loan. If you paying EMI for your Home Loan which is significantly more than Rs. __________ per lakh of the loan amount, then it is time to transfer your loan to another bank. We, at Real Value, can assist you with this</p>
                    </div>
                </div>
            </div>

            <div className={classes.whyHold}>
                <p className={classes.whyHead}>Why Sri Apti</p>
                <div className={classes.whyCardHold}>
                    <div className={classes.whyCard}>
                        <img src={door} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>Doorstep service</p>
                    </div>
                    <div className={classes.whyCard}>
                        <img src={clock} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>Sanctions for Loan in record time</p>
                    </div>
                    <div className={classes.whyCard}>
                        <img src={leader} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>Skilled and Trained team</p>
                    </div>
                    <div className={classes.whyCard}>
                        <img src={visible} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>Transparent process</p>
                    </div>
                    <div className={classes.whyCard}>
                        <img src={transparent} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>No hidden costs and administrative charges</p>
                    </div>
                    <div className={classes.whyCard}>
                        <img src={exprt} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>Home Loan Experts to guide you through the entire process </p>
                    </div>
                    <div className={classes.whyCard}>
                        <img src={network} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>Very large network of tieups with banks and NBFCs.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeLoan;