import classes from './Footer.module.css';
import logo from '../Nav/logo.png';
import facebook from './facebook.png';
import linked from './linkedin.png';
import insta from './instagram.png';
import loc from './asset/location.png';
import mail from './asset/email.png';
import phone from './asset/smartphone.png';
import hrt from './asset/heart.gif';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (<div className={classes.container}>
        <div className={classes.hold}>
            <div>
                <img src={logo} className={classes.logo}></img>
                <div className={classes.socialHold}>
                    <a target="_blank" href='https://www.facebook.com/sriapticonsultancy'><img src={facebook} className={classes.insta}></img></a>
                    <a target="_blank" href='https://www.linkedin.com/in/sri-apti-consultancy-8064b8285/'> <img src={linked} className={classes.insta}></img></a>
                    <a target="_blank" href='https://www.instagram.com/sriapticonsultancy_financial/'> <img src={insta} className={classes.insta}></img></a>

                </div>
            </div>
            <div className={classes.linksHold}>
                <p className={classes.extraHead}>Extra Links
                    <hr color='red' className={classes.line}></hr></p>
                <div className={classes.extra}>
                    <div>
                        <Link to='/'><li className={classes.li}>Home</li></Link>
                        <Link to='/about'><li className={classes.li}>About Us</li></Link>
                        <Link to='/emiCalculator'><li className={classes.li}>EMI Calculator</li></Link>
                        <Link to='/contact'><li className={classes.li}>Contact Us</li></Link>
                    </div>
                    <div>
                        <Link to='/homeloan'><li className={classes.li}>Home Loan</li></Link>
                        <Link to='/businessloan'><li className={classes.li}>Business Loan</li></Link>
                        <Link to='/propertyloan'><li className={classes.li}>Property Loan</li></Link>
                        <Link to='/smeloan'><li className={classes.li}>MSME Loan</li></Link>
                        <Link to='/cardloan'><li className={classes.li}>Construction Loan</li></Link>
                        <Link to='/lifeInsurance'><li className={classes.li}>Life Insurance</li></Link>
                        <Link to='/generalInsurance'><li className={classes.li}>General Insurance</li></Link>
                    </div>
                </div>

            </div>
            <div className={classes.reachHold}>
                <p className={classes.extraHead}>Reach Us
                    <hr color='red' className={classes.line}></hr></p>
                <div className={classes.locHold}>
                    <img src={loc} className={classes.reach}></img>
                    <p className={classes.locTxt}>
                        107, 'A' Wing, 1st Floor, Sohrab Hall, Tadiwala Road, Pune-411001</p>

                </div>
                <div className={classes.locHold}>
                    <img src={mail} className={classes.reach}></img>
                    <p className={classes.locTxt}>sriapticonsultancy@gmail.com</p>
                </div>
                <div className={classes.locHold}>
                    <img src={phone} className={classes.reach}></img>
                    <p className={classes.locTxt}>9158000255 / 9960786665 / 020-29952495</p>
                </div>
            </div>

        </div>
        <p className={classes.company}>Copyright &#169; 2023 SriApti Consultancy | Made with
            <img src={hrt} className={classes.hrt}></img> by Cortica Web Solutions Pvt. Ltd</p>
    </div>
    )
}

export default Footer;