import { Component } from "react";
import './Nav.css';
import logo from './logo.png';
import { Link } from 'react-router-dom';

import homeImg from './home.png';
import service from './24-hours-support.png';
import contact from './contact-book.png';
import sett from './setting.png';
import calender from './calendar.png';
import abt from './about.png';

class Navigation extends Component {
    state = { clicked: false };
    handleClick = () => {
        this.setState({
            clicked: !this.state.clicked
        })
    };
    render() {
        return (<div>

            <div className="navHold">

                <div>

                    <nav className="navbarItems">

                        <div className="logoHold">
                            <img src={logo} className="comlogo2"></img>
                            <p className="logoLine">Loans. Invest. Grow. Prosper</p>
                        </div>
                        <div className="menu-icons" onClick={this.handleClick}>
                            <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                        </div>
                        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                            <li className="list"><img src={homeImg} className="navIcon"></img><Link className="linker" to='/' span={true} smooth={true}>Home</Link></li>
                            <li className="list"><img src={abt} className="navIcon"></img><Link className="linker" to='/about' span={true} smooth={true}>About Us</Link></li>
                            <li >
                                <div class="dropdown">
                                    <p class="dropdownContent"><img src={sett} className="navIcon"></img>Services</p>
                                    <div class="dropdown-content">
                                        <Link to='/homeloan'>Home Loan</Link>
                                        <Link to='/businessloan'>Business Loan</Link>
                                        <Link to='/propertyloan'>Property Loan</Link>

                                        <Link to='/smeloan'>MSME Loan</Link>
                                        <Link to='/cardloan'>Construction Finance</Link>
                                        <Link to='/lifeInsurance'>Life Insurance</Link>
                                        <Link to='/generalInsurance'>General Insurance</Link>
                                    </div>
                                </div>

                            </li>
                            <li className="list"><Link className="linker" to='/emiCalculator' span={true} smooth={true}><img src={calender} className="navIcon"></img>EMI Calculator</Link></li>
                            <li className="list"><Link className="linker" to='/contact' span={true} smooth={true}><img src={contact} className="navIcon"></img>Contact Us</Link></li>


                        </ul>
                    </nav>
                </div>
            </div >


        </div>
        )
    }
};

export default Navigation;

