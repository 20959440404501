import classes from './Service.module.css';
import Card from './Card';

import personal from './asset/user.png';
import edu from './asset/college-graduation.png';
import home from './asset/home.png';
import brief from './asset/briefcase.png';
import property from './asset/apartment.png'
import msme from './asset/working-factory.png';
import credit from './asset/credit-card.png';
import life from './asset/insurance.png';
import general from './asset/insurance (1).png';
import machine from './asset/machinery.png';
import gurantor from './asset/guarantor.png';
import LC from './asset/mail.png';
import overDraft from './asset/draft.png';
import car from './asset/car.png';
import health from './asset/health-insurance.png';

import construction from './asset/construction-site.png';
import agri from './asset/harvest.png';
import lease from './asset/house-key.png';
import discount from './asset/tag.png';

const Service = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head}>Services We Provide</p>
            <p className={classes.head2}>Your happiness is our success.</p>
            <hr color='red' width='71rem'></hr>

            <div className={classes.cardhold}>
                <Card image={personal} txt='Personal Loan'></Card>
                <Card image={brief} txt='Business Loan'></Card>
                <Card image={edu} txt='Education Loan'></Card>
                <Card image={home} txt='Home Loan'></Card>

                <Card image={property} txt='Property Loan'></Card>
                <Card image={msme} txt='MSME Loan'></Card>
                <Card image={machine} txt='Machinery Loan'></Card>
                <Card image={gurantor} txt='Bank Guarantee'></Card>
                <Card image={LC} txt='Letter of Credit ( LC )'></Card>
                <Card image={overDraft} txt='CashCredit / Overdraft (CC / OD)'></Card>
                <Card image={car} txt='Car Loan (New car / used car) '></Card>
                <Card image={construction} txt='Construction Finance/Loan '></Card>
                <Card image={agri} txt='Agri Loans'></Card>
                <Card image={lease} txt='Leasing & Equity'></Card>
                <Card image={discount} txt='Bill Discounting'></Card>

                <Card image={life} txt='Life Insurance'></Card>
                <Card image={health} txt='Health Insurance'></Card>
                <Card image={general} txt='General Insurance'></Card>
            </div>
        </div>
    )
}

export default Service;