import classes from './Why.module.css';
import advice from './asset/advice.png';

import target from './asset/target.png';

const Why = () => {
    return (<div className={classes.contain}>

        <p className={classes.head}>Why Choose Us</p>
        <hr color='red' width='71rem' className={classes.line}></hr>
        <div className={classes.hold}>

            <div className={classes.card}>
                <p className={classes.cardHead}><img src={target} className={classes.icon}></img> Client-Centered</p>
                <p className={classes.cardtxt}>At our firm, the client always comes first. We are in this business to serve others and make a positive impact on their lives. With a passion for helping people, we enjoy building lifelong relationships with our clients and guiding them along their financial path.</p>
            </div>

            <div className={classes.card}>
                <p className={classes.cardHead}><img src={target} className={classes.icon}></img> A Team Approach</p>
                <p className={classes.cardtxt}>We are a team of dedicated and experienced professionals specializing in wealth management and financial planning. Beyond our internal team, we partner with CPAs, attorneys and advisory groups that provide us with advanced departments for further insight.</p>
            </div>

            <div className={classes.card}>
                <p className={classes.cardHead}><img src={advice} className={classes.icon}></img> Comprehensive Advice</p>
                <p className={classes.cardtxt}>When creating your financial strategies, we address your entire financial picture, from your short-term and immediate needs to your long-term goals. By offering comprehensive and integrated advice, we strive to provide clarity regarding your financial path and help you feel confident in your future.</p>
            </div>

            <div className={classes.card}>
                <p className={classes.cardHead}><img src={advice} className={classes.icon}></img> Independent</p>
                <p className={classes.cardtxt}>As an independent firm, we are not confined to proprietary services or sales quotas. we are more concerned with building relationships and creating thorough and comprehensive financial strategies that address your short and long-term goals.</p>
            </div>
        </div>
    </div>
    )
}

export default Why;