import classes from './AboutCard.module.css';
import star from './asset/star.png';

const AboutCard = (props) => {
    return (
        <div className={classes.card}>
            <img src={star} alt='' className={classes.star}></img>
            <p className={classes.head}>{props.head}</p>
            <p className={classes.txt}>{props.txt}</p>
        </div>
    )
}

export default AboutCard;