import React, { useState } from 'react';
import classes from "./EmiHome.module.css";
import logo from "./asset/logo.png";
import star from "./asset/star.png";
import safe from "./asset/safe.png";


function EmiHome() {

    const [userValues, setUserValues] = useState({
        amount: '',
        interest: '',
        years: '',
    });

    const [results, setResults] = useState({
        monthlyPayment: '',
        totalPayment: '',
        totalInterest: '',
        isResult: false,
    });

    const handleInputChange = (event) =>
        setUserValues({ ...userValues, [event.target.name]: event.target.value });

    const handleSubmitValues = (e) => {
        e.preventDefault();
        console.log(userValues);
        calculateResults(userValues);
    };

    const calculateResults = ({ amount, interest, years }) => {
        const userAmount = Number(amount);
        const calculatedInterest = Number(interest) / 100 / 12;
        const calculatedPayments = Number(years) * 12;
        const x = Math.pow(1 + calculatedInterest, calculatedPayments);
        const monthly = (userAmount * x * calculatedInterest) / (x - 1);

        if (isFinite(monthly)) {
            const monthlyPaymentCalculated = monthly.toFixed(2);
            const totalPaymentCalculated = (monthly * calculatedPayments).toFixed(2);
            const totalInterestCalculated = (monthly * calculatedPayments - userAmount).toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                monthlyPayment: monthlyPaymentCalculated,
                totalPayment: totalPaymentCalculated,
                totalInterest: totalInterestCalculated,
                isResult: true,
            });
        }
        return;
    };

    return (
        <div className={classes.container}>
            <div className={classes.left}>
                <h1>Home Loan EMI Calculator Jul 2023</h1>
                <hr className={classes.line} color='red' width='71rem'></hr>
                <p><i class="fa-solid fa-clock-rotate-left"></i>Last Updated 5th July 2023</p>

                <div className={classes.tble}>
                    <p><i class="fa-regular fa-hand-point-right"></i>Loan EMI is calculated at 8.50% for illustration purpose.</p>
                    <p><i class="fa-regular fa-hand-point-right"></i>Banks offering lowest EMI on home loan are Bank of Baroda, Axis Bank, Kotak Mahindra Bank, HDFC Bank and ICIC Bank.</p>

                    <h3>Home Loan Calculator</h3>
                    <p>Current repo rate stands at 6.5%</p>

                    <table>
                        <tr>
                            <th>Loan Amount</th>
                            <th>20 year loan</th>
                            <th>25 year loan</th>
                            <th>30 year loan</th>
                        </tr>
                        <tr>
                            <td>₹ 30 Lakh</td>
                            <td>₹  26,035</td>
                            <td>₹ 24,157</td>
                            <td>₹ 23,067</td>
                        </tr>
                        <tr>
                            <td>₹ 50 Lakh</td>
                            <td>₹ 43,391</td>
                            <td>₹ 40,261</td>
                            <td>₹ 38,446</td>
                        </tr>
                        <tr>
                            <td>₹ 1 crore</td>
                            <td>₹ 86,782</td>
                            <td>₹ 80,523</td>
                            <td>₹ 76,891</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className={classes.right1}>
                <div><p className={classes.headtitle}>EMI Calculator</p></div>
                <div className={classes.rightIn1}>
                    <form onSubmit={handleSubmitValues}>
                        <div className={classes.rightIn11}>
                            <div className={classes.loanHold}>
                                <p className={classes.loan1}>Loan Amount (Rs.):</p>
                                <input className={classes.in} type="text" name='amount' value={userValues.amount} onChange={handleInputChange}></input>
                            </div>
                            <div className={classes.loanHold}>
                                <p className={classes.loan1}>Interest Rate:</p>
                                <input className={classes.in} type="text" name='interest' value={userValues.interest} onChange={handleInputChange}></input>
                            </div>
                        </div>
                        <div className={classes.rightIn22}>
                            <div>
                                <p>Loan Tenure:</p>
                                <input className={classes.in} type="text" name='years' value={userValues.years} onChange={handleInputChange}></input><br></br>
                                <button className={classes.loanbtn}>calculate</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div className={classes.resulthold}>
                    <label className={classes.lab}>Monthly EMI:</label>
                    <input className={classes.resultIn} type="text" value={results.monthlyPayment} ></input><br></br>
                    <label className={classes.lab}>Total Payment:</label>
                    <input className={classes.resultIn} type="text" value={results.totalPayment} ></input><br></br>
                    <label className={classes.lab}>Total Interest:</label>
                    <input className={classes.resultIn} type="text" value={results.totalInterest}></input><br></br>

                </div>

            </div>
        </div>
    );

};
export default EmiHome;