import { useEffect } from 'react';
import classes from './Business.module.css';
import insurance from './asset/insurance.png';

const GeneralIn = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={classes.Hold}>
            <div className={classes.titlehold}>
                <p className={classes.title}>Genral Insurance</p>
            </div>

            <div className={classes.divsHold}>
                <div className={classes.left}>
                    <p className={classes.leftIn}>Features And Benefits</p>
                    <p className={classes.leftIn}>Of Our</p>
                    <p className={classes.leftIn}>Genral Insurance</p>
                    <div className={classes.homehold}><img src={insurance} className={classes.home}></img></div>
                </div>
                <div className={classes.middle}>
                    <div className={classes.card}>
                        <p className={classes.cardTitle}>Unique Variants</p>
                        <p className={classes.cardtxt}>Choose the loan type that suits you the best- Term Loan, Flexi Term Loan, Flexi Hybrid Loan.</p>
                    </div>
                    <div className={classes.card}>
                        <p className={classes.cardTitle}>No part-prepayment charge on Flexi variants.</p>
                        <p className={classes.cardtxt}>You can prepay a portion of your loan at no extra charges with our Flexi Term Loan and Flexi Hybrid Loan.</p>
                    </div>
                    <div className={classes.card}>
                        <p className={classes.cardTitle}>Convenient Tenure.</p>
                        <p className={classes.cardtxt}>Get the added flexibility to pay back your loan with repayment options ranging from 12 months to 96 months.</p>
                    </div>
                </div>
                <div className={classes.right}>
                    <div className={classes.card1}>
                        <p className={classes.cardTitle1}>Loan of upto Rs. 50 lakh</p>
                        <p className={classes.cardtxt1}>Manage your small or large business expenses with loans ranging from Rs. 50,000 to Rs.50 Lakh.</p>
                    </div>
                    <div className={classes.card1}>
                        <p className={classes.cardTitle1}>No Hidden Charges</p>
                        <p className={classes.cardtxt1}>All fees and charges are mentioned upfront in the loan document. We advise you to read it in detail.</p>
                    </div>
                    <div className={classes.card1}>
                        <p className={classes.cardTitle1}>No Collateral Required</p>
                        <p className={classes.cardtxt1}>You do not have to worry about providing any collateral or security to get our business loan.</p>
                    </div>
                </div>
            </div>

            <div className={classes.eligibilityHold}>
                <p className={classes.eligiHead1}>Eligibility criteria and documents required</p>
                <p className={classes.eligiHead2}>Anyone can apply for our business loan if they meet the five basic criteria mentioned below. If you meet the all the eligibility criteria, you will need a set of documents to complete the documents process.</p>

                <div className={classes.docHold}>
                    <div className={classes.doc}>
                        <p className={classes.dochead}>Eligibility Criteria</p>
                        <ul className={classes.list}>
                            <li>Nationality: Indian</li>
                            <li>Business Vintage: Atleast 3 years</li>
                            <li>CIBIL Score: 685 or higher</li>
                            <li>Work Status: Self-employed</li>
                            <li>Age: 24 years to 70 years</li>
                        </ul>
                    </div>
                    <div className={classes.doc}>
                        <p className={classes.dochead}>Documents</p>
                        <ul className={classes.list}>
                            <li>KYC documents- Aadhar/Pan Card/Passport/Voter's ID</li>
                            <li>Medical Tests:Insurers would typically demand underwriting to get a medical test to understand your health.</li>
                            <li>Other financial documents</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralIn;