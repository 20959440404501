import classes from './Ceo.module.css';
import ceoImg from './asset/ceoImg.jpeg';
import cofound from './asset/cofounder2.png';
import cofounder from './asset/ceo22.jpeg';
import sign from './asset/sign.png';

/*<p className={classes.abt3}>Mr.Abhijit Gujrathi with a degree in BCom, started his business in the year 2008. All this time he collected only those values that helped us(company) become the best in our business. We have collaborated with hundreds of companies around the world and have been able to achieve the trust of each of them.</p>
                <p>Under his leadership, we are confident in our ability to deliver exceptional financial services and build lasting relationships with 3000+ valued clients.</p> 
                
                <p className={classes.abt3}>Mr.Sandeep Tarade holds an impressive academic background and qualifications in the financial domain. With a Degree in BA, he has demonstrated a deep understanding of complex financial concepts and industry best practices.</p>
                <p >With exceptional talent in fostering strong client relationships has made him a sought - after financial expert among a diverse clientele. He has established a vast and loyal client base of 4500+, ranging from high - net - worth individuals to institutional investors. </p>
                */

const Ceo = () => {
    return (<div className={classes.container}>
        <p className={classes.founder}>Our Founders</p>
        <hr color='red' width='71rem'></hr>
        <div className={classes.hold}>
            <div className={classes.left}>
                <img src={ceoImg} className={classes.ceo}></img>
            </div>
            <div className={classes.right}>
                <p className={classes.abt}>ABOUT ME</p>
                <p className={classes.abt2}>Trusted Business Founder</p>
                <p className={classes.abt3}>Mr.Abhijit Gujrathi with a degree in BCom, started his job in banking sector at ABN Amro Bank as a Sales Manager in Housing Loan & Unsecured Loans and gained total 04 years of experience in banking-loan sector.</p>
                <p>After persuing job he gain knowledge of all products & decided to start his own venture/consultancy from April 2008 onwards. We have collaborated with hundreds of companies around the world and have been able to achieve the trust of each of them.</p>
                <p>Under his leadership, we are confident in our ability to deliver exceptional financial services and build lasting relationships with 3000+ valued clients.</p>
                <p>Proud member of BNI CHAMPS (East & North).</p>
                <div className={classes.signhold}>
                    <p className={classes.abt4}>Abhijit Gujrathi</p>


                </div>
                <p className={classes.found}>Founder </p>
                <p className={classes.abt6}>9158000255</p>
            </div>
        </div>

        <div className={classes.hold}>

            <div className={classes.right}>
                <p className={classes.abt}>ABOUT ME</p>
                <p className={classes.abt2}>Established Business Ally</p>
                <p className={classes.abt3}>Mr.Sandeep Tarade with a degree in BA, started his first job in banking sector at Axis Bank as a Senior Manager in Retail Banking, with total experience of 13 years in banking Products (FD, Saving, Current A/c's, Credit Card, etc.) he shifted to DCB & was promoted to Deputy Branch Manager in Retail Banking & had 01 year of experience.</p>
                <p className={classes.abt33}>After going through 14+ years of experience in Retail Banking, he decided to start his own consultancy from October 2020 onwards. </p>
                <div className={classes.signhold}>
                    <p className={classes.abt4}>Sandeep Tarade</p>

                </div>
                <p className={classes.abt5}>Managing Director </p>
                <p className={classes.abt6}>9960786665</p>
            </div>

            <div className={classes.left}>
                <img src={cofound} className={classes.ceo}></img>
            </div>

        </div>
    </div >
    )
}

export default Ceo;