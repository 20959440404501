import classes from './Advantage.module.css';
import AboutCard from './AboutCard';

const Advantage = () => {
    return (<div className={classes.contain}>
        <p className={classes.mainhead}>Advantages Of Dealing With Sri Apti</p>
        <hr color='red' width='71rem'></hr>
        <div className={classes.hold}>

            <div className={classes.top}>
                <AboutCard head='Product Portfolio' txt='Home Loan, Loan against Property, Commercial Purchases, Personal Loan, Business Loan'></AboutCard>
                <AboutCard head='Relationship Plus' txt='We have received Award for Top Performing channel partner from PNB-HFL, ICICI, SBI'></AboutCard>
                <AboutCard head='Experienced Outlook' txt='Our Core Team has veterans in various cases including professional, Salaried & Self-Employed, to helping client needs as per their requirements.'></AboutCard>
            </div>
            <div className={classes.bottom}>
                <AboutCard head='Delivering Efficiency' txt='Our strong relationship with the lenders ensures our clients get Preferential treatment.'></AboutCard>
                <AboutCard head='One-Stop-Solution' txt="We ensure the solutions to the client' s with multiple options from various Banks & NBFC's"></AboutCard>
                <AboutCard head='Knowledge-Sharing' txt='Our sales team is clued in to the latest changes in financial knowledge & effective process.'></AboutCard>
            </div>
        </div >
    </div>
    )
}

export default Advantage;