import classes from './Card.module.css';

const Card = (props) => {
    return (
        <div className={classes.card}>
            <p className={classes.head}>{props.title}</p>
            <p className={classes.txt}>{props.txt}</p>
        </div>
    )
}

export default Card;