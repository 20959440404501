import { useEffect } from 'react';
import classes from './Aboutpage.module.css';
import Vision from "../components/About/Vision";
import Advantage from "../components/About/Advantage";
import Ceo from '../components/Home/Ceo';
import logo from '../components/Nav/logo.png';

import clock from '../components/About/asset/wall-clock.png';
import door from '../components/About/asset/doorstep.png';
import leader from '../components/About/asset/leader.png';
import visible from '../components/About/asset/visibility.png';
import transparent from '../components/About/asset/opacity.png';
import exprt from '../components/About/asset/leadership.png';
import network from '../components/About/asset/networking.png';



const AboutPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={classes.hold}>
            <Vision></Vision>
            <Ceo></Ceo>

            <div className={classes.newHold}>
                <p className={classes.newHead}>Foundation Of Sri Apti</p>
                <hr color='red' width='71rem'></hr>
                <div className={classes.newHold2}>
                    <div className={classes.newLeft}>
                        <p>Since 2008, Mr. Abhijit Gujrathi is in Banking Sector
                            as a consultant. Mr. Sandeep Tarade approached him for expansion
                            in loan consultancy & join hands from October 2020 onwards.</p>
                        <p>Since, this collaboration has succesfully worked for last 3 years, We
                            decided to form a LLP for PAN India Consultancy Services in Loans.</p>
                    </div>
                    <div className={classes.newRight}>
                        <img src={logo} className={classes.newLogo}></img>
                    </div>
                </div>
            </div>

            <Advantage></Advantage>

            <div className={classes.whyHold}>
                <p className={classes.whyHead}>Why Sri Apti</p>
                <hr color='red' width='71rem'></hr>
                <div className={classes.whyCardHold}>
                    <div className={classes.whyCard}>
                        <img src={door} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>Doorstep service</p>
                    </div>
                    <div className={classes.whyCard}>
                        <img src={clock} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>Sanctions for Loan in record time</p>
                    </div>
                    <div className={classes.whyCard}>
                        <img src={leader} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>Skilled and Trained team</p>
                    </div>
                    <div className={classes.whyCard}>
                        <img src={visible} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>Transparent process</p>
                    </div>
                    <div className={classes.whyCard}>
                        <img src={transparent} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>No hidden costs and administrative charges</p>
                    </div>
                    <div className={classes.whyCard}>
                        <img src={exprt} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>Home Loan Experts to guide you through the entire process </p>
                    </div>
                    <div className={classes.whyCard}>
                        <img src={network} className={classes.whyIcon}></img>
                        <p className={classes.whyTxt}>Very large network of tieups with banks and NBFCs.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage;