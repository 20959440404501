import classes from './Contact.module.css';
import { useEffect } from 'react';
import Card from './Card';
import call from './asset/smartphone.png';
import mail from './asset/email.png';
import loc from './asset/location.png';

import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Toast from '../Toast/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
//service_gx643lk  template_vxghqx4
const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_gx643lk', 'template_vxghqx4', form.current, 'MGHJc9msCNQI5UPKS')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });


    };

    const notify = () => {
        toast.success('Message has been sent, thankyou!', {
            position: toast.POSITION.TOP_CENTER
        });
    }

    return (
        <div className={classes.hold}>
            <div className={classes.titlehold}>
                <p className={classes.title}>Contact Us</p>
            </div>

            <div className={classes.cardHold}>
                <Card image={loc} title='Office' txt="107, 'A' Wing, 1st Floor, Sohrab Hall, Tadiwala Road, Pune-411001"></Card>
                <Card image={call} title='Call Us' txt='9960786665/  9158000255/  (020) 29952495'></Card>
                <Card image={mail} title='Email' txt='sriapticonsultancy@gmail.com'></Card>
            </div>

            <div className={classes.bottomHold}>
                <div className={classes.formHold}>
                    <form ref={form} onSubmit={sendEmail}>
                        <input name="user_name" className={classes.in} type='text' placeholder='Name'></input>
                        <input name="user_email" className={classes.in} type='text' placeholder='Email'></input><br></br>
                        <input name="user_contact" className={classes.in} type='text' placeholder='Mobile'></input>
                        <input name="user_subject" className={classes.in} type='text' placeholder='Subject'></input><br></br>
                        <textarea name="user_message" className={classes.in2} placeholder='message'></textarea>
                        <button onClick={notify} type="submit" value="Send" className={classes.btn}>Submit</button>
                        <ToastContainer />
                    </form>
                </div>
                <div className={classes.getHold}>
                    <p className={classes.getHead}>Get In Touch</p>
                    <p className={classes.getTxt}>Feel free to get in touch with us. We are always open to discuss
                        creative ideas or oportunities to be part of your visions.</p>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;