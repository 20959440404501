import { useEffect } from 'react';
import classes from './Card.module.css';
import construction from './asset/construction-site.png';

const CardLoan = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={classes.hold}>
            <div className={classes.titlehold}>
                <p className={classes.title}>Construction Finance/Loan</p>
            </div>

            <div className={classes.divsHold}>
                <div className={classes.left}>
                    <p className={classes.leftIn}>Features And Benefits</p>
                    <p className={classes.leftIn}>Of Our</p>
                    <p className={classes.leftIn}>Construction Loan</p>
                    <div className={classes.homehold}><img src={construction} className={classes.home}></img></div>
                </div>
                <div className={classes.middle}>
                    <div className={classes.card}>
                        <p className={classes.cardTitle}>Interest Rate</p>
                        <p className={classes.cardtxt}>Construction Loan interest rates tend to be higher than Home Loan. This is because they are not widely available or opted for individually.</p>
                    </div>
                    <div className={classes.card}>
                        <p className={classes.cardTitle}>Documentation.</p>
                        <p className={classes.cardtxt}>Approval from Municipal Corporation and Architect-authorised construction blueprint is must for Construction Loan.</p>
                    </div>
                    <div className={classes.card}>
                        <p className={classes.cardTitle}>Convenient Tenure.</p>
                        <p className={classes.cardtxt}>Get the added flexibility to pay back your loan with repayment options ranging from 10 years to 15 years.</p>
                    </div>
                </div>
                <div className={classes.right}>
                    <div className={classes.card1}>
                        <p className={classes.cardTitle1}>World Class Post Disbursement Services</p>
                        <p className={classes.cardtxt1}>We provide a dedicated team of experienced employees and coverage for ultimate customer satisfaction – all tuned to the highest standards of ethics, integrity and transparency.</p>
                    </div>
                    <div className={classes.card1}>
                        <p className={classes.cardTitle1}>Customised Offerings</p>
                        <p className={classes.cardtxt1}>We tailor our offer to suit your budget, home loan eligibility and construction requirement.</p>
                    </div>
                    <div className={classes.card1}>
                        <p className={classes.cardTitle1}>Disbursal of the loan</p>
                        <p className={classes.cardtxt1}>In the case of Construction Loans, the lender will transfer the loan amount in installments.</p>
                    </div>
                </div>
            </div>

            <div className={classes.eligibilityHold}>
                <p className={classes.eligiHead1}>Eligibility criteria and documents required</p>
                <p className={classes.eligiHead2}>Anyone can apply for our business loan if they meet the five basic criteria mentioned below. If you meet the all the eligibility criteria, you will need a set of documents to complete the documents process.</p>

                <div className={classes.docHold}>
                    <div className={classes.doc}>
                        <p className={classes.dochead}>Eligibility Criteria</p>
                        <ul className={classes.list}>
                            <li>Nationality: Indian</li>
                            <li>Minimum CIBIL score 611</li>
                            <li>Work Status: Self-employed or Salaried</li>
                            <li>Age: 24 years to 70 years</li>
                        </ul>
                    </div>
                    <div className={classes.doc}>
                        <p className={classes.dochead}>Documents</p>
                        <ul className={classes.list}>
                            <li>KYC documents- Aadhar/Pan Card/Passport/Voter's ID</li>
                            <li>Approval from the Municipal Corporation</li>
                            <li>Architect-authorised construction blueprint</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardLoan;