import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';



import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import Root from './pages/Root'

import HomeLoan from './LoanPages/HomeLoan';
import BusinessLoan from './LoanPages/BusinessLoan';
import PropertyLoan from './LoanPages/PropertyLoan';
import SmeLoan from './LoanPages/SmeLoan';
import CardLoan from './LoanPages/CardLoan';
import LifeIn from './LoanPages/LifeIn';
import GeneralIn from './LoanPages/GeneralIn';
import ContactUs from './components/Contact/Contact';
import Emi from './components/EMI/Emi';

const router = createBrowserRouter([{
  path: '/',
  element: <Root></Root>,

  children: [
    { path: '/', element: <HomePage></HomePage> },
    { path: '/about', element: <AboutPage></AboutPage> },
    { path: '/homeloan', element: <HomeLoan></HomeLoan> },
    { path: '/businessloan', element: <BusinessLoan></BusinessLoan> },
    { path: '/propertyloan', element: <PropertyLoan></PropertyLoan> },
    { path: '/smeloan', element: <SmeLoan></SmeLoan> },
    { path: '/cardloan', element: <CardLoan></CardLoan> },
    { path: '/lifeInsurance', element: <LifeIn></LifeIn> },
    { path: '/generalInsurance', element: <GeneralIn></GeneralIn> },
    { path: '/contact', element: <ContactUs></ContactUs> },
    { path: '/emiCalculator', element: <Emi></Emi> },


  ]
}]);



const App = () => {
  return <RouterProvider router={router}></RouterProvider>

};

export default App;