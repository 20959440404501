import classes from './Hero.module.css';
import MySlide from '../Slider/Slide';

const Hero = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.headhold}><p className={classes.head}>
                WELCOME TO <span className={classes.s}>S</span>RI <span className={classes.aa}>A</span>PTI</p></div>
            <div className={classes.slidehold}>
                <MySlide></MySlide>
            </div>
        </div >
    )
}

export default Hero;