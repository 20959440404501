import { useState } from 'react';
import classes from './Apply.module.css';
import home from './asset/home.png';
import brief from './asset/briefcase.png';
import apartment from './asset/apartment.png';
import assets from './asset/assets.png';
import health from './asset/healthcare.png';
import life from './asset/life-insurance.png';
import credit from './asset/credit-card.png';
import construction from './asset/construction.png';
import { Link } from 'react-router-dom';

const Apply = () => {
    const [loan, setLoan] = useState(true);

    const chgHnd = () => {
        setLoan(!loan);
    }

    return (
        <div className={classes.hold}>
            <div className={classes.headhold}>
                <p className={classes.head}>I would like to apply for </p>
                <select className={classes.sel} onChange={chgHnd}>
                    <option className={classes.sel}>Loans</option>
                    <option className={classes.sel} >Insurance</option>
                </select>

            </div>

            {loan && <div className={classes.loanHold}>
                <div className={classes.loanIconHold}>
                    <Link to='/homeloan'><img src={home} className={classes.icon}></img></Link>
                    <Link to='/businessloan'><img src={brief} className={classes.icon}></img></Link>
                    <Link to='/propertyloan'><img src={apartment} className={classes.icon}></img></Link>
                    <Link to='/smeloan'><img src={assets} className={classes.icon}></img></Link>
                    <Link to='/cardloan'><img src={construction} className={classes.icon}></img></Link>

                </div>
                <div className={classes.loanIconHold2}>
                    <p>Home Loans</p>
                    <p>Business Loans</p>
                    <p>Property Loans</p>
                    <p>SME Loans</p>
                    <p>Construction Finance</p>
                </div>
            </div>}


            {!loan && <div className={classes.InsuranceHold}>
                <div className={classes.InsuranceIconHold}>
                    <Link to='/lifeInsurance'><img src={health} className={classes.icon}></img></Link>
                    <Link to='/generalInsurance'><img src={life} className={classes.icon}></img></Link>

                </div>
                <div className={classes.InsuranceIconHold2}>
                    <p>Life Insurance</p>
                    <p>General Insurance</p>
                </div>
            </div>}

            <div className={classes.btnHold}>
                <Link to='/contact' className={classes.linker}><button className={classes.btn}>Contact Us</button></Link>
            </div>
        </div>
    )
}

export default Apply;