import React, { Component } from "react";
import classes from './Slide.module.css';
import Slider from "react-slick";
import Card from "./Card";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Fade extends Component {
    render() {
        const settings = {
            dots: true,
            fade: true,
            infinite: true,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <div className={classes.hold}>

                <Slider {...settings}>
                    <div>
                        <Card title='Protecting You And Your Family'
                            txt='Trusted by millions of Indians for generations'></Card>
                    </div>
                    <div>
                        <Card title='Pick the Right Insurance Policy For You'
                            txt="India's most trusted financial service brand"></Card>
                    </div>
                    <div>
                        <Card title='Being with financial consultant'
                            txt='Focus on providing customers with the best services.'></Card>
                    </div>
                    <div>
                        <Card title='Loan for all types of Financial Needs'
                            txt='Whether its Higher education, Marriage, Construction etc. We cover everything'></Card>
                    </div>
                </Slider>
            </div>
        );
    }
}