import { useEffect } from 'react';
import Apply from "../components/Home/Apply";
import Num from "../components/Home/Num";
import Ceo from "../components/Home/Ceo";
import Why from "../components/Home/Why";
import Contact from "../components/Home/Contact";
import Hero from "../components/Home/Hero/Hero";
import Service from "../components/Home/Services/Service";
import Partner from "../components/Home/Partner/Partner";
import Header2 from "../components/Nav/Header";
import EmiHome from "../components/Home/Calculator/EmiHome";
import NewWhatsapp from '../components/Whatsapp/Whatsapp';

const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>

            <Hero></Hero>

            <Service></Service>
            <Apply></Apply>
            <EmiHome></EmiHome>
            <Why></Why>
            <Num></Num>
            <NewWhatsapp></NewWhatsapp>
            <Partner></Partner>
            <Contact></Contact>

        </div>
    )
}

export default HomePage;