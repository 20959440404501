import classes from './Num.module.css';

const Num = () => {
    return (
        <div className={classes.hold}>

            <p className={classes.head}>Our Achievements</p>

            <hr color='red' width='71rem'></hr>
            <div className={classes.nosHold}>
                <div className={classes.top}>
                    <div>
                        <p className={classes.number}>16+</p>
                        <p className={classes.numTxt}>YEARS OF SERVICE</p>
                    </div>

                    <div>
                        <p className={classes.number}>10+</p>
                        <p className={classes.numTxt}>Employees</p>
                    </div>

                    <div>
                        <p className={classes.number}>10,000+</p>
                        <p className={classes.numTxt}>SATISFIED CUSTOMERS</p>
                    </div>

                    <div>
                        <p className={classes.number}>100+</p>
                        <p className={classes.numTxt}>AWARDS</p>
                    </div>




                </div>

            </div>
        </div>
    )
}

export default Num;