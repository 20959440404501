import classes from './Partner.module.css';
import aadi from './asset/aditya-birla.png';
import axis from './asset/axis.png';
import dbs from './asset/dbs.png';
import sbi from './asset/sbi.png';
import federal from './asset/fedral-bank..png';
import baroda from './asset/bankofbaroda..png';
import central from './asset/Central_Bank_of_India_.png';
import hdfc from './asset/hdfc.png';

import pnb from './asset/Pnb-Housing-Finance-Logo-.png';
import bulls from './asset/indiabulls.png';
import idbi from './asset/IDBI-Bank.png';
import kotak from './asset/kotak..png';
import india from './asset/Bank-of-India..png';
import piramal from './asset/piramal-house.png';
import rbl from './asset/rbl..png';
import shriram from './asset/sriram.png';
import SC from './asset/standard charted.png';
import tata from './asset/tata.png';
import yes from './asset/yes.png';
import au from './asset/au.png';
import hsbc from './asset/hsbc_logo.png';
import shinhan from './asset/shinhan.png';
import vastu from './asset/vastu_logo.png';
import repco from './asset/Repco_logo.png';
import sundaram from './asset/sundaram.png';
import Hero from './asset/heroFin.png';
import ede from './asset/Edelweiss_logo.png';
import bajaj from './asset/bajaj.png';
import aadhar from './asset/aadharHousing.png';

import bnkOfInd from './asset/Bank-of-India..png';
import union from './asset/union.png';
import maha from './asset/Bank_of_Maharashtra_logo.svg.png';
import canara from './asset/canara.png';
import punjab from './asset/Pnb-Housing-Finance-Logo-.png';
import icicibank from './asset/icici.png';
import idfc from './asset/idfc.png';
import bandhan from './asset/bandhan.png';
import hsbcbank from './asset/hsbc.png';
import dcbbank from './asset/dcb.png';
import indus from './asset/indusland.png';

import godrej from './asset/godrej.png';
import mahindra from './asset/mahindra.png';
import muthoot from './asset/muthoot.png';
import punjabNat from './asset/punjab.png';
import ujjwal2 from './asset/ujjivan2.jpg';


/*
 <img src={aadi} className={classes.icon}></img>
                
                <img src={bulls} className={classes.icon}></img>
                <img src={india} className={classes.icon}></img>
                
                <img src={rbl} className={classes.icon}></img>
                
                */

const Partner = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head}>Choice of Partners With Us</p>
            <hr color='red' width='71rem'></hr>
            <div className={classes.bankHold}>
                <img src={central} className={classes.icon}></img>
                <img src={sbi} className={classes.icon}></img>
                <img src={baroda} className={classes.icon}></img>

                <img src={bnkOfInd} className={classes.icon}></img>
                <img src={union} className={classes.icon}></img>
                <img src={maha} className={classes.icon}></img>
                <img src={canara} className={classes.icon}></img>
                <img src={punjabNat} className={classes.icon}></img>
                <img src={hdfc} className={classes.icon}></img>
                <img src={icicibank} className={classes.icon}></img>
                <img src={kotak} className={classes.icon}></img>
                <img src={axis} className={classes.icon}></img>
                <img src={SC} className={classes.icon}></img>
                <img src={idfc} className={classes.icon}></img>
                <img src={yes} className={classes.icon}></img>
                <img src={hsbc} className={classes.icon}></img>
                <img src={federal} className={classes.icon}></img>
                <img src={shinhan} className={classes.icon}></img>
                <img src={bandhan} className={classes.icon}></img>
                <img src={idbi} className={classes.icon}></img>
                <img src={dbs} className={classes.icon}></img>
                <img src={rbl} className={classes.icon}></img>
                <img src={dcbbank} className={classes.icon}></img>
                <img src={indus} className={classes.icon}></img>

            </div>

            <p className={classes.nbfcHead}>Our NBFC Partners</p>
            <hr color='red' width='71rem'></hr>

            <div className={classes.bankHold}>
                <img src={aadi} className={classes.icon}></img>
                <img src={pnb} className={classes.icon}></img>
                <img src={au} className={classes.icon}></img>
                <img src={tata} className={classes.icon}></img>
                <img src={shriram} className={classes.icon}></img>
                <img src={vastu} className={classes.icon}></img>
                <img src={repco} className={classes.icon}></img>
                <img src={sundaram} className={classes.icon}></img>
                <img src={piramal} className={classes.icon}></img>
                <img src={Hero} className={classes.icon}></img>
                <img src={ede} className={classes.icon}></img>
                <img src={bajaj} className={classes.icon}></img>
                <img src={aadhar} className={classes.icon}></img>
                <img src={godrej} className={classes.icon}></img>
                <img src={mahindra} className={classes.icon}></img>
                <img src={muthoot} className={classes.icon}></img>
                <img src={ujjwal2} className={classes.icon}></img>



            </div>
        </div>
    )
}

export default Partner;