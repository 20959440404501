import { useEffect } from 'react';
import classes from './Sme.module.css';
import msme from './asset/supermaket.png';

const SmeLoan = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={classes.hold}>
            <div className={classes.titlehold}>
                <p className={classes.title}>MSME Loan</p>
            </div>

            <div className={classes.divsHold}>
                <div className={classes.left}>
                    <p className={classes.leftIn}>Features And Benefits</p>
                    <p className={classes.leftIn}>Of Our</p>
                    <p className={classes.leftIn}>MSME Loans</p>
                    <div className={classes.homehold}><img src={msme} className={classes.home}></img></div>
                </div>
                <div className={classes.middle}>
                    <div className={classes.card}>
                        <p className={classes.cardTitle}>Move quickly from planning to execution</p>
                        <p className={classes.cardtxt}>Our superfast turnaround time means that you can take things from planning to execution within a matter of days instead of weeks or months.</p>
                    </div>
                    <div className={classes.card}>
                        <p className={classes.cardTitle}>No part-prepayment charge on Flexi variants.</p>
                        <p className={classes.cardtxt}>You can prepay a portion of your loan at no extra charges with our Flexi Term Loan and Flexi Hybrid Loan.</p>
                    </div>
                    <div className={classes.card}>
                        <p className={classes.cardTitle}>Convenient Tenure.</p>
                        <p className={classes.cardtxt}>Get the added flexibility to pay back your loan with repayment options ranging from 12 months to 96 months.</p>
                    </div>
                </div>
                <div className={classes.right}>
                    <div className={classes.card1}>
                        <p className={classes.cardTitle1}>Loan of upto Rs. 50 lakh</p>
                        <p className={classes.cardtxt1}>Manage your small or large business expenses with loans ranging from Rs. 50,000 to Rs.50 Lakh.</p>
                    </div>
                    <div className={classes.card1}>
                        <p className={classes.cardTitle1}> Manage your company capital better</p>
                        <p className={classes.cardtxt1}>Our MSME / SME loans can be used for a number of purposes. You can either use them to expand your operations by buying plant and machinery or opening at a new location.</p>
                    </div>
                    <div className={classes.card1}>
                        <p className={classes.cardTitle1}>No Collateral Required</p>
                        <p className={classes.cardtxt1}>You do not have to worry about providing any collateral or security to get our business loan.</p>
                    </div>
                </div>
            </div>

            <div className={classes.eligibilityHold}>
                <p className={classes.eligiHead1}>Eligibility criteria and documents required</p>
                <p className={classes.eligiHead2}>Anyone can apply for our business loan if they meet the five basic criteria mentioned below. If you meet the all the eligibility criteria, you will need a set of documents to complete the documents process.</p>

                <div className={classes.docHold}>
                    <div className={classes.doc}>
                        <p className={classes.dochead}>Eligibility Criteria</p>
                        <ul className={classes.list}>
                            <li>Nationality: Indian</li>
                            <li>Business Vintage: Atleast 3 years</li>
                            <li>CIBIL Score: 685 or higher</li>
                            <li>Work Status: Self-employed</li>
                            <li>Age: 24 years to 70 years</li>
                        </ul>
                    </div>
                    <div className={classes.doc}>
                        <p className={classes.dochead}>Documents</p>
                        <ul className={classes.list}>
                            <li>KYC documents- Aadhar/Pan Card/Passport/Voter's ID</li>
                            <li>Proof of business ownership</li>
                            <li>Other financial documents</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SmeLoan;