import { useEffect, useState } from 'react';
import classes from './Emi.module.css';

const Emi = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [userValues, setUserValues] = useState({
        amount: '',
        interest: '',
        years: '',
    });

    const [results, setResults] = useState({
        monthlyPayment: '',
        totalPayment: '',
        totalInterest: '',
        isResult: false,
    });

    const handleInputChange = (event) =>
        setUserValues({ ...userValues, [event.target.name]: event.target.value });

    const handleSubmitValues = (e) => {
        e.preventDefault();
        console.log(userValues);
        calculateResults(userValues);
    };

    const calculateResults = ({ amount, interest, years }) => {
        const userAmount = Number(amount);
        const calculatedInterest = Number(interest) / 100 / 12;
        const calculatedPayments = Number(years) * 12;
        const x = Math.pow(1 + calculatedInterest, calculatedPayments);
        const monthly = (userAmount * x * calculatedInterest) / (x - 1);

        if (isFinite(monthly)) {
            const monthlyPaymentCalculated = monthly.toFixed(2);
            const totalPaymentCalculated = (monthly * calculatedPayments).toFixed(2);
            const totalInterestCalculated = (monthly * calculatedPayments - userAmount).toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                monthlyPayment: monthlyPaymentCalculated,
                totalPayment: totalPaymentCalculated,
                totalInterest: totalInterestCalculated,
                isResult: true,
            });
        }
        return;
    };



    return (
        <div className={classes.hold}>
            <div className={classes.titlehold}>
                <p className={classes.title}>Emi Calculator</p>
            </div>

            <div className={classes.right1}>
                <div><p className={classes.headtitle}>EMI Calculator</p></div>
                <div className={classes.rightIn1}>
                    <form onSubmit={handleSubmitValues}>
                        <div className={classes.rightIn11}>
                            <div className={classes.loanHold}>
                                <p className={classes.loan1}>Loan Amount (Rs.):</p>
                                <input className={classes.in}
                                    type="text" name='amount' value={userValues.amount} onChange={handleInputChange}></input>
                            </div>
                            <div className={classes.loanHold}>
                                <p className={classes.loan1}>Interest Rate:</p>
                                <input className={classes.in}
                                    type="text" name='interest' value={userValues.interest} onChange={handleInputChange}></input>
                            </div>
                        </div>
                        <div className={classes.rightIn22}>
                            <div>
                                <p>Loan Tenure:</p>
                                <input className={classes.in}
                                    type="text" name='years' value={userValues.years} onChange={handleInputChange}></input><br></br>
                                <button className={classes.loanbtn}>calculate</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div className={classes.resulthold}>
                    <label className={classes.lab}>Monthly EMI:</label>
                    <input type="text" value={results.monthlyPayment} className={classes.res}></input><br></br>
                    <label className={classes.lab}>Total Payment:</label>
                    <input type="text" value={results.totalPayment} className={classes.res}></input><br></br>
                    <label className={classes.lab}>Total Interest:</label>
                    <input type="text" value={results.totalInterest} className={classes.res}></input><br></br>

                </div>

            </div>
        </div>
    )
}

export default Emi;