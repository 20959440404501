import classes from './Vision.module.css';

const Vision = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.mainhold}>
                <p className={classes.mainhead}>About Us</p>
                <p className={classes.mainhead2}>A Journey Through Time</p>
            </div>
            <p className={classes.head}>Vision and Mission</p>
            <hr color='red' width='71rem'></hr>

            <div className={classes.visionHold}>
                <p className={classes.sp}>Vision - </p>
                <p className={classes.txt}>To become the preferred choice of financial services partner for India’s aspiring classes, meeting the full range of their credit requirements, and helping India become a financially inclusive society where every citizen has ready access to formal channels of finance. </p>
                <p className={classes.sp}>Mission - </p>
                <p className={classes.txt}>Sri Apti Consultancy is dedicated to the mission of bringing convenience to people’s lives and making their lives easier. We offer secured and unsecured credit to meet their varied financial needs from instant loans to microfinance, affordable home loans, vehicle finance and more.</p>

            </div>
        </div>
    )
}

export default Vision;